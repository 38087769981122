* {
	margin: 0;
	padding: 0;
}

a,
a:hover,
a:focus {
	text-decoration: none;
	outline: none;
	transition: all 0.3s ease-in-out;
}

img,
iframe,
video {
	max-width: 100%;
}

table {
	width: 100%;
}

img {
	border: none;
	max-width: 100%;
	height: auto;
}

ol,
ul {
	list-style: none;
}

fieldset {
	border: 2px groove threedface;
	display: block;
	padding-block-end: 0.75em;
	padding-block-start: 0.35em;
	text-align: center;
}

fieldset legend {
	display: inline-block;
	color: inherit;
	background: none;
	vertical-align: top;
}

::-webkit-selection {
	background: $second_color;
	color: $white;
}

::-moz-selection {
	background: $second_color;
	color: $white;
}

::selection {
	background: $second_color;
	color: $white;
}

::-webkit-input-placeholder {
	// -webkit-transition: text-indent 0.5s ease, color 0.5s ease;
	// transition: text-indent 0.5s ease, color 0.5s ease;
	color: #969696;
}

input::-moz-placeholder {
	// -webkit-transition: text-indent 0.5s ease, color 0.5s ease;
	// transition: text-indent 0.5s ease, color 0.5s ease;
	color: #969696;
}

input::ms-input-placeholder {
	// -webkit-transition: text-indent 0.5s ease, color 0.5s ease;
	// transition: text-indent 0.5s ease, color 0.5s ease;
	color: #969696;
}

[placeholder]:focus::-webkit-input-placeholder {
	// text-indent: 10em;
	// color: transparent;
}

[placeholder]:focus::-moz-placeholder {
	text-indent: 10em;
	color: transparent;
}

body,
html {
	font-family: $font, 'Helvetica Neue', Helvetica, Arial, sans-serif;
	color: $text_color;
	background: $web_bg;
	font-size: $font_size;
}

.title {
	font-family: $title;
}

.color-main {
	color: $main_color;
}

.form-control,
button,
input,
select {
	transition: all 0.3s ease-in-out;
	outline: none !important;
	box-shadow: none;
	cursor: pointer;

	&:hover,
	&:focus,
	&:active {
		outline: none !important;
		box-shadow: none !important;
		border-color: $main_color;
	}
}

// fix bootstrap modal bug
body {
	padding-right: 0px !important;
}

body {
	min-height: 100vh;
}

.bg-light {
	background-color: $white !important;
}

@include max-md() {
	input,
	select {
		font-size: 18px !important;
	}
}

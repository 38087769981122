/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
	max-width: 500px;
	min-height: 52px;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}

.Toastify__toast-container--top-center {
}

.Toastify__toast-container--top-right {
}

.Toastify__toast-container--bottom-left {
}

.Toastify__toast-container--bottom-center {
}

.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
	border-radius: 5px;
	padding: 10px 20px;
	min-height: 55px;
}

.Toastify__toast--rtl {
}

.Toastify__toast--dark {
}

.Toastify__toast--default {
}

.Toastify__toast--info {
}

.Toastify__toast--success {
}

.Toastify__toast--warning {
}

.Toastify__toast--error {
}

.Toastify__toast-body {
	font-size: 18px;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
	align-self: center;
	font-size: 0px;
	width: 30px;
	height: 30px;
	background: url(../assets/img/close.svg) no-repeat;
	background-position: center;
}

.Toastify__close-button--default {
}

.Toastify__close-button > svg {
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
	// display: none;
	opacity: 0 !important;
}

.Toastify__progress-bar--animated {
}

.Toastify__progress-bar--controlled {
}

.Toastify__progress-bar--rtl {
}

.Toastify__progress-bar--default {
}

.Toastify__progress-bar--dark {
}

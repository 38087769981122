@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import '../var';

@each $key, $value in $theme-colors {
	.color-#{$key} {
		color: $value !important;
	}
}

@each $key, $value in $theme-colors {
	.bg-#{$key} {
		background-color: $value !important;
	}
}

$cursors: alias, all-scroll, auto, cell, context-menu, col-resize, copy, crosshair, default, e-resize, ew-resize, grab, grabbing, help, move, n-resize, ne-resize, nesw-resize, ns-resize, nw-resize,
	nwse-resize, no-drop, none, not-allowed, pointer, progress, row-resize, s-resize, se-resize, sw-resize, text, w-resize, wait, zoom-in, zoom-out;

@each $cur in $cursors {
	.cursor-#{$cur} {
		cursor: $cur !important;
	}
}

.modal {
	.modal-content {
		border: none;
		border-radius: 6px;
		padding: 18px;
	}

	.modal-title {
		font-weight: bold;
		font-size: 27px;
		line-height: 32px;
		color: #000000;
	}

	.modal-header {
		border-bottom: 0;
		position: relative;

		.close {
			display: none;
		}

		.modal-close {
			position: absolute;
			top: 0px;
			right: 1px;
			width: 25px;
			height: 25px;
			padding: 0;
			border: none;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: $white;

			&:hover,
			&:focus {
				outline: none;
			}
		}
	}

	.modal-footer {
		border-top: none;
	}
}

.icon {
	user-select: none;
}

.order_status {
	padding: 5px 15px;
	background: #c7f8d3;
	border-radius: 15px;
	font-size: 14px;
	color: #141414;
	font-weight: normal;
	display: inline-block;
	position: relative;
	padding-left: 30px;

	&::before {
		content: '';
		width: 15px;
		height: 15px;
		border: 2px solid #00ad0c;
		border-radius: 100%;
		position: absolute;
		top: 8px;
		left: 8px;
	}

	&.SKETCH,
	&.CUSTOMER_REVIEW_SKETCH {
		background: #ffebac;

		&::before {
			border: 2px solid #c69500;
		}
	}

	&.COLOR,
	&.CUSTOMER_REVIEW_COLOR {
		background: #ffddc1;

		&::before {
			border: 2px solid #ea7a00;
		}
	}

	&.EXPORT_FILE {
		background: #ffd9d9;

		&::before {
			border: 2px solid #db0000;
		}
	}

	&.DONE {
		background: #ececec;

		&::before {
			border: 2px solid #cccccc;
		}
	}
}

.box {
	background: $white;
	border: 1px solid #e2e5ed;
	box-sizing: border-box;
	// box-shadow: inset 0px 1px 0px rgba(102, 113, 123, 0.21);
	border-radius: 3px;
	padding: 15px;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	&__title {
		font-size: 20px;
		color: $black;
		font-weight: bold;
	}

	&__sub_title {
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 0.2625px;
		color: #141414;
	}

	&__link {
		font-weight: normal !important;
		font-size: 16px !important;
		line-height: 22px;
		letter-spacing: 0.3px;
		color: #337fd7 !important;
		word-wrap: break-word;
	}

	&__control {
		font-size: 16px;
		text-align: right;
		letter-spacing: 0.3px;
		color: #337fd7;
		padding: 5px;
		background: none;
		border: none;
	}

	&__device {
		border-top: 1px solid #dfe3e8;
		margin: 20px 0;
		margin-left: -15px;
		margin-right: -15px;
	}
}

.rte {
	ul,
	ol {
		list-style: initial;
		padding-left: 15px;
	}

	ul ul {
		list-style-type: circle;
	}
}

.react-tiny-popover-container {
	z-index: 1111;
}

.cus-checkbox {
	background: linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%);
	border: 1px solid #337fd7;
	// box-shadow: 0px 1px 0px rgba(170, 170, 170, 0.1);
	border-radius: 4px;
	width: 20px;
	height: 20px;
	position: relative;
	z-index: 1;
	cursor: pointer;
	user-select: none;
	margin-bottom: 0;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		&:after {
			content: '';
			display: none;
			width: 5px;
			height: 10px;
			border: solid #337fd7;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
	}

	input:checked ~ .checkmark:after {
		display: block;
	}
}

.text-blue {
	color: #337fd7 !important;
}

.text-gray {
	color: #767676 !important;
}

.text-black {
	color: #141414 !important;
}

.toggle {
	.icon {
		// opacity: 0;
		cursor: pointer;
		display: none;
	}

	&:hover {
		&::after {
			// opacity: 1;
			display: initial;
		}

		.icon {
			// opacity: 1;
			display: initial;
		}
	}
}
.z-index-999 {
	z-index: 999;
}
